export const AZURE_STORAGE_CONN_STRING =
  "DefaultEndpointsProtocol=https;AccountName=rollzproddocuments;AccountKey=DHPhnyTPM0uLQXrF5Cn0TmF4fJXPOGaot8ai9qSv7I32hvwu5OcsK2Vml7k8P/CfQekbY00QGxBb+AStTgz0fA==;EndpointSuffix=core.windows.net";
export const AZURE_STORAGE_KEY =
  "DHPhnyTPM0uLQXrF5Cn0TmF4fJXPOGaot8ai9qSv7I32hvwu5OcsK2Vml7k8P/CfQekbY00QGxBb+AStTgz0fA==";
export const STORAGE_ACCOUNT = "rollzproddocuments";
export const REPORT_CONTAINER = "report";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const title = {
  label1: "Bihar Government",
  label2: "Har Ghar Nal Jal Yojna",
  subTitle: {
    phed: "Public Health Engineering Department",
    udhd: "Urban Development and Housing Department",
    prd: "Panchayati Raj Department",
  },
};

export const colors = {
  textGreen: "#10b759",
  textYellow: "#fbbc06",
};

export const priColor = "#727cf5";

export const operationalInitialValue = {
  nonFunctional: 0,
  functional: 0,
  functionalWLS: 0,
  functionalPWS: 0,
  nonFunctionalWLS: 0,
  nonFunctionalPWS: 0,
};

export const ISSUE_TYPES = {
  "SCHEME NON-FUNCTIONAL": [
    "CT Removed",
    "CT Not Working",
    "Motor Burn",
    "Starter Burn",
    "No Starter/ Direct Motor Operation",
    "Motor Not Running Due To Pipe Leakege",
    "Motor Not Running Due To Operator's Non Payment",
    "Other",
  ],
  "DEVICE NON-FUNCTIONAL": [
    "Power Cable Removed/Burn",
    "All Device Setup Burn",
    "Electric Meter Burn",
    "No Electricity Supply/Fault At Scheme",
    "Electricity Prepaid Meter Not Rechrge/Fault",
    "Power Not Showing In Device",
    "All Device Setup Removed By Operator/Contractor",
    "Other",
  ],
};

export const userIdentityTypes = [
  { label: "PHED User", value: "PHED_USER" },
  { label: "NIC User", value: "NIC_USER" },
  { label: "Geron/Rollz Employee", value: "GERON_" },
  { label: "Other", value: "OTHER" },
];

export const geronEmpTypes = [
  { label: "Firmware operator", value: "GERON_FIRMWARE_OPERATOR" },
  { label: "Data Operator", value: "GERON_DATA_OPERATOT" },
  { label: "GIS Operator", value: "GERON_GIS_OPERATOR" },
  { label: "Mobile App User", value: "MOBILE_APP_USER" },
  { label: "Caller", value: "GERON_CALLING" },
];

export const mobileAppUserType = [
  { label: "Head /Zonal Office", value: "HO" },
  { label: "Division Supervisor", value: "DS" },
  { label: "Technical Executive", value: "TE" },
];

export const surveyDataKeys = [
  "survey_data",
  "installation_data",
  "verification",
  "isInstalled",
  "installation_ts",
  "deviceImage",
  "fw_device_id",
  "doi",
  "regData",
  "id",
];

//Temp solution
export const NON_FUNCTIONAL_REPORTS = [
  {
    id: "1",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Muzaffarpur.xlsx",
  },
  {
    id: "2",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sitamarhi.xlsx",
  },
  {
    id: "3",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sheohar.xlsx",
  },
  {
    id: "4",
    name: "Saran",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Saran.xlsx",
  },
  {
    id: "5",
    name: "GopalGanj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Gopalganj.xlsx",
  },
  {
    id: "6",
    name: "Siwan",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Siwan.xlsx",
  },
  {
    id: "7",
    name: "Aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Aurangabad.xlsx",
  },
  {
    id: "8",
    name: "Buxar",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Buxar.xlsx",
  },
  {
    id: "9",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Jehanabad.xlsx",
  },
  {
    id: "10",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sheikhpura.xlsx",
  },
  {
    id: "11",
    name: "LAKHISARAI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Lakhisarai.xlsx",
  },
  {
    id: "12",
    name: "Jamui",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Jamui.xlsx",
  },
  {
    id: "13",
    name: "KHAGARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Khagaria.xlsx",
  },
  {
    id: "14",
    name: "Banka",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Banka.xlsx",
  },
  {
    id: "15",
    name: "MUNGER",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Munger.xlsx",
  },
  {
    id: "16",
    name: "SUPAUL",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Supaul.xlsx",
  },
  {
    id: "17",
    name: "Saharsa",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Saharsa.xlsx",
  },
  {
    id: "18",
    name: "MADHEPURA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Madhepura.xlsx",
  },
  {
    id: "19",
    name: "ARARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Araria.xlsx",
  },
  {
    id: "20",
    name: "Purnia",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Purnia.xlsx",
  },
  {
    id: "21",
    name: "KISHANGANJ",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Kishanganj.xlsx",
  },
  {
    id: "22",
    name: "KATIHAR",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Katihar.xlsx",
  },
  {
    id: "25",
    name: "Arwal",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Arwal.xlsx",
  },
  //? UDHD DEPARTMENT DIVISIONS
  {
    id: "201",
    name: "aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Aurangabad.xlsx",
  },
  {
    id: "202",
    name: "begusarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Begusarai.xlsx",
  },
  {
    id: "203",
    name: "Chapra",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Chapra.xlsx",
  },
  {
    id: "204",
    name: "Gaya",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Gaya.xlsx",
  },
  {
    id: "205",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Jehanabad.xlsx",
  },
  {
    id: "206",
    name: "Katihar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Katihar.xlsx",
  },
  {
    id: "207",
    name: "Lakhisarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Lakhisarai.xlsx",
  },
  {
    id: "208",
    name: "Madhepura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Madhepura.xlsx",
  },
  {
    id: "209",
    name: "Munger",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Munger.xlsx",
  },
  {
    id: "210",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Muzaffarpur.xlsx",
  },
  {
    id: "211",
    name: "Nalanda",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nalanda.xlsx",
  },
  {
    id: "212",
    name: "PATNA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patna.xlsx",
  },
  {
    id: "213",
    name: "PATNA EAST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patnaeast.xlsx",
  },
  {
    id: "214",
    name: "PATNA WEST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patnawest.xlsx",
  },
  {
    id: "215",
    name: "PURNEA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Purnea.xlsx",
  },
  {
    id: "216",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sitamarhi.xlsx",
  },
  {
    id: "217",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Siwan.xlsx",
  },
  {
    id: "218",
    name: "West Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Westchamparan.xlsx",
  },
  {
    id: "219",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sheikhpura.xlsx",
  },
  {
    id: "220",
    name: "Araria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Araria.xlsx",
  },
  {
    id: "221",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Arwal.xlsx",
  },
  {
    id: "222",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Banka.xlsx",
  },
  {
    id: "223",
    name: "Bhagalpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Bhagalpur.xlsx",
  },
  {
    id: "224",
    name: "Bhojpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Bhojpur.xlsx",
  },
  {
    id: "225",
    name: "Biharsharif",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Biharsharif.xlsx",
  },
  {
    id: "226",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Buxar.xlsx",
  },
  {
    id: "227",
    name: "East Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Eastchamparan.xlsx",
  },
  {
    id: "228",
    name: "Gopalganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Gopalganj.xlsx",
  },
  {
    id: "229",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Jamui.xlsx",
  },
  {
    id: "230",
    name: "Khagaria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Khagaria.xlsx",
  },
  {
    id: "231",
    name: "Kishanganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Kishanganj.xlsx",
  },
  {
    id: "232",
    name: "Nawada",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nawada.xlsx",
  },
  {
    id: "233",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sheohar.xlsx",
  },
  {
    id: "234",
    name: "Supaul",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Supaul.xlsx",
  },
  {
    id: "235",
    name: "Vaishali",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Vaishali.xlsx",
  },
  {
    id: "236",
    name: "Samastipur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Samastipur.xlsx",
  },
  //? PRD DEPT
  {
    id: "301",
    name: "BETTIAH",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Bettiah.xlsx",
  },
  {
    id: "302",
    name: "SITAMARHI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Sitamarhi.xlsx",
  },
];

export const NON_REPORTING_REPORTS = [
  {
    id: "1",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Muzaffarpur.xlsx",
  },
  {
    id: "2",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sitamarhi.xlsx",
  },
  {
    id: "3",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sheohar.xlsx",
  },
  {
    id: "4",
    name: "Saran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Saran.xlsx",
  },
  {
    id: "5",
    name: "GopalGanj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Gopalganj.xlsx",
  },
  {
    id: "6",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Siwan.xlsx",
  },
  {
    id: "7",
    name: "Aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Aurangabad.xlsx",
  },
  {
    id: "8",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Buxar.xlsx",
  },
  {
    id: "9",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Jehanabad.xlsx",
  },
  {
    id: "10",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sheikhpura.xlsx",
  },
  {
    id: "11",
    name: "LAKHISARAI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Lakhisarai.xlsx",
  },
  {
    id: "12",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Jamui.xlsx",
  },
  {
    id: "13",
    name: "KHAGARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Khagaria.xlsx",
  },
  {
    id: "14",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Banka.xlsx",
  },
  {
    id: "15",
    name: "MUNGER",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Munger.xlsx",
  },
  {
    id: "16",
    name: "SUPAUL",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Supaul.xlsx",
  },
  {
    id: "17",
    name: "Saharsa",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Saharsa.xlsx",
  },
  {
    id: "18",
    name: "MADHEPURA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Madhepura.xlsx",
  },
  {
    id: "19",
    name: "ARARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Araria.xlsx",
  },
  {
    id: "20",
    name: "Purnia",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Purnia.xlsx",
  },
  {
    id: "21",
    name: "KISHANGANJ",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Kishanganj.xlsx",
  },
  {
    id: "22",
    name: "KATIHAR",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Katihar.xlsx",
  },
  {
    id: "25",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Arwal.xlsx",
  },
  //? UDHD DEPARTMENT DIVISIONS
  {
    id: "201",
    name: "aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Aurangabad.xlsx",
  },
  {
    id: "202",
    name: "begusarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Begusarai.xlsx",
  },
  {
    id: "203",
    name: "Chapra",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Chapra.xlsx",
  },
  {
    id: "204",
    name: "Gaya",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Gaya.xlsx",
  },
  {
    id: "205",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Jehanabad.xlsx",
  },
  {
    id: "206",
    name: "Katihar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Katihar.xlsx",
  },
  {
    id: "207",
    name: "Lakhisarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Lakhisarai.xlsx",
  },
  {
    id: "208",
    name: "Madhepura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Madhepura.xlsx",
  },
  {
    id: "209",
    name: "Munger",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Munger.xlsx",
  },
  {
    id: "210",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Muzaffarpur.xlsx",
  },
  {
    id: "211",
    name: "Nalanda",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Nalanda.xlsx",
  },
  {
    id: "212",
    name: "PATNA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patna.xlsx",
  },
  {
    id: "213",
    name: "PATNA EAST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patnaeast.xlsx",
  },
  {
    id: "214",
    name: "PATNA WEST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patnawest.xlsx",
  },
  {
    id: "215",
    name: "PURNEA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Purnea.xlsx",
  },
  {
    id: "216",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sitamarhi.xlsx",
  },
  {
    id: "217",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Siwan.xlsx",
  },
  {
    id: "218",
    name: "West Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Westchamparan.xlsx",
  },
  {
    id: "219",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sheikhpura.xlsx",
  },
  {
    id: "220",
    name: "Araria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Araria.xlsx",
  },
  {
    id: "221",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Arwal.xlsx",
  },
  {
    id: "222",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Banka.xlsx",
  },
  {
    id: "223",
    name: "Bhagalpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Bhagalpur.xlsx",
  },
  {
    id: "224",
    name: "Bhojpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Bhojpur.xlsx",
  },
  {
    id: "225",
    name: "Biharsharif",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Biharsharif.xlsx",
  },
  {
    id: "226",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Buxar.xlsx",
  },
  {
    id: "227",
    name: "East Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Eastchamparan.xlsx",
  },
  {
    id: "228",
    name: "Gopalganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Gopalganj.xlsx",
  },
  {
    id: "229",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Jamui.xlsx",
  },
  {
    id: "230",
    name: "Khagaria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Khagaria.xlsx",
  },
  {
    id: "231",
    name: "Kishanganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Kishanganj.xlsx",
  },
  {
    id: "232",
    name: "Nawada",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Nawada.xlsx",
  },
  {
    id: "233",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sheohar.xlsx",
  },
  {
    id: "234",
    name: "Supaul",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Supaul.xlsx",
  },
  {
    id: "235",
    name: "Vaishali",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Vaishali.xlsx",
  },
  {
    id: "236",
    name: "Samastipur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Samastipur.xlsx",
  },
  //? PRD DEPT
  {
    id: "301",
    name: "BETTIAH",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Nonreporting_Bettiah.xlsx",
  },
  {
    id: "302",
    name: "SITAMARHI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Nonreporting_Sitamarhi.xlsx",
  },
];
