import { Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../../components/common/CustomToolbar";
import { useNavigate } from "react-router";

const useStyles = makeStyles({
    root: {
        "& .scheme-data-table": {
            fontWeight: "bold",
        },
    },
});

const EelectricityList = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        let tableDataTemp = [];
        if (props.schemes && props.schemes.length) {
            props.schemes.map((page, i) => {
                if (props.showWardsIds && props.showWardsIds.length) {
                    if (!props.showWardsIds.includes(page["id"])) {
                        return;
                    }
                }

                tableDataTemp.push({
                    ...page,
                });
            });
        }

        setTableData(tableDataTemp);
    }, [props.schemes]);

    const columns = [
        {
            field: "startDate",
            headerName: "Start Date",
            hide: false,
            width: 200,
            sortable: false,
        },
        {
            field: "endDate",
            headerName: "End Date",
            width: 200,
            sortable: false,
        },
        {
            field: "totalDuration",
            headerName: "Total Duration",
            width: 200,
            sortable: false,
        },
    ];

    return (
        <Card>
            <div style={{ height: 500, width: "100%" }} className={classes.root}>
                <DataGrid
                    disableSelectionOnClick
                    rows={tableData}
                    columns={columns}
                    pageSize={40}
                    rowsPerPageOptions={[5]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            title: props?.title || ' ',
                            fileName: props?.fileName,
                            locationName: props?.locationName,
                        },
                    }}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    localeText={{ noRowsLabel: "No Records  Found :)" }}
                />
            </div>
        </Card>
    );
};

export default EelectricityList;
