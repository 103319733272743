import moment from "moment-timezone";
import { priColor, title } from "../constants";
import * as PDFLib from "pdf-lib";
import * as XLSX from "xlsx";

function currentDateTime(getWhat = "both") {
  const date = new Date();
  if (getWhat === "both") return date.toLocaleString();
  else if (getWhat === "date") {
    return date.toLocaleDateString();
  }
}

function convertToDate(datetime) {
  //datetime = datetime.replace(/-/g, "/")
  if (datetime) {
    var date = new Date(datetime);
  } else {
    date = new Date();
  }
  var opDate = date.getTime() / 1000;
  return opDate;
}

function convertToFilterDate(datetime, setToEnd = false, isBefore12AM = false) {
  //datetime = datetime.replace(/-/g, "/")
  var date = new Date();
  if (datetime) {
    date = new Date(datetime);
    if (setToEnd) {
      //to set starting of next day
      date.setDate(date.getDate() + 1);
    }
  }

  date.setHours(0, 0, 0, 0);
  if (isBefore12AM) {
    date.setHours(23, 59, 0, 0);
  }
  var opDate = date.getTime() / 1000;
  return opDate;
}

function getDiffInMin(from, to) {
  return Math.abs(to - from) / 60;
}

function getDateTime(unixts) {
  let lrtime = moment.unix(unixts).format("h:mm:ss A");
  let lrdate = moment.unix(unixts).format("DD/MM/YYYY");
  return { date: lrdate, time: lrtime };
}

function formateDateTime(_ts, no_ts = "") {
  if (!_ts) return no_ts;
  let dateTime = moment.unix(_ts).format("DD-MMMM-YYYY : h:mm A");
  return dateTime;
}

export const filterActualValues = (input) => {
  const filterFn = (value) =>
    value !== null &&
    value !== undefined &&
    value !== "null" &&
    value !== "undefined" &&
    (typeof value === "number" ||
      (typeof value === "string" && value.trim() !== "" && value !== ""));

  if (Array.isArray(input)) {
    return input.filter(filterFn);
  } else {
    return filterFn(input) ? input : null;
  }
};

function convertNumToTime(n) {
  try {
    // Check sign of given number
    let number = n;
    if (number === undefined || number === null || isNaN(number)) {
      number = 0;
    }
    var sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    // Add Sign in final result
    sign = sign === 1 ? "" : "-";

    // Concate hours and minutes
    const time = sign + hour + ":" + minute;

    return time;
  } catch (E) {
    return "00:00";
  }
}

function historyDate(lastXdays = 5, type) {
  if (type !== "currentDay") {
    var cdate = new Date();
    cdate.setHours(0, 0, 0, 0);
    const cTS = cdate.getTime();

    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - lastXdays);
    // if(lastXdays == 0){
    lastDate.setHours(0, 0, 0, 0);
    // }
    const lastXDayTS = lastDate.getTime();

    return [lastXDayTS / 1000, cTS / 1000];
  } else {
    cdate = new Date();
    const cTS = cdate.getTime();

    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - lastXdays);
    // if(lastXdays == 0){
    lastDate.setHours(0, 0, 0, 0);
    // }
    const lastXDayTS = lastDate.getTime();

    return [lastXDayTS / 1000, cTS / 1000];
  }
}

function get24HoursDateRange() {
  var newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  const startDate = newDate.getTime();

  newDate.setHours(23, 59, 59, 0);
  const endDate = newDate.getTime();

  return [Math.floor(startDate / 1000), Math.floor(endDate / 1000)];
}

function checkIfRoleExist(userRoleString, allowedRoles) {
  let userRoles = userRoleString.split(",");
  return userRoles.some((e) => allowedRoles.includes(e));
}

function getSGMStrength(v) {
  if (v < 2) {
    return "No Signal";
  } else if (v >= 2 && v < 10) {
    return "Poor";
  } else if (v >= 10 && v < 15) {
    return "Ok";
  } else if (v >= 15 && v < 20) {
    return "Good";
  } else {
    return "Excellent";
  }
}

function getDeviceDataFlag(d) {
  let f = {
    102: "Power On",
    114: "Motor On",
    105: "Motor Off",
    101: "Device Tamper",
    100: "Device HB",
    103: "Device HB",
    108: "Device HB",
    107: "Device HB",
    106: "Device HB",
    110: "Device HB",
    113: "Device HB",
    118: "Device HB",
    117: "Device HB",
    116: "Device HB",
    112: "Power On",
    111: "Device Tamper",
  };

  return f[`${d.p}${d.l}${d.m}`];
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

function getInstallationDate(wardData) {
  if (wardData && wardData.doi) {
    const datetime = getDateTime(wardData.doi);
    return `${datetime["date"]} ${datetime["time"]}`;
  }
  if (
    wardData &&
    wardData.installation_data &&
    wardData.installation_data.date
  ) {
    return wardData.installation_data.date;
  } else {
    const datetime = getDateTime(wardData._ts);
    return `${datetime["date"]} ${datetime["time"]}`;
  }
}

function getInstallationDate2(wardData) {
  if (
    wardData &&
    wardData?.installation_data &&
    wardData?.installation_data?._ts
  ) {
    const dateTime = getDateTime(wardData?.installation_data?._ts);
    return `${dateTime["date"]} ${dateTime["time"]}`;
  } else if (
    wardData &&
    wardData?.installation_data &&
    wardData?.installation_data?.date
  ) {
    return wardData?.installation_data?.date;
  }
}

function getInstallationDate3(wardData) {
  if (
    wardData &&
    wardData?.installation_data &&
    wardData?.installation_data?.status &&
    wardData?.installation_data?.status?.date
  ) {
    return wardData?.installation_data?.status?.date;
  } else {
    const dateTime = getDateTime(wardData?._ts);
    return `${dateTime["date"]} ${dateTime["time"]}`;
  }
}

function getSubTitle() {
  var full = window.location.host;
  var parts = full.split(".");
  var sub = parts[0];
  return title["subTitle"][sub]
    ? title["subTitle"][sub]
    : title["subTitle"]["phed"];
}

function getDeptName() {
  let hostnameArray = window.location.hostname.split(".");
  if (hostnameArray.length === 3) {
    return hostnameArray[0].toUpperCase();
  }
  return "PHED";
}

function getPanchayatTextAppend() {
  if (getDeptName() === "UDHD") {
    return " / Muncipal Council";
  } else {
    return "";
  }
}

export const InstallationStatusColor = (v) => {
  if (v === "Approved") return "green";
  else if (v === "Pending") return "red";
  else if (v === "Pending For Approval") return priColor;
  else return "red";
};

function getInstallationStatus(wardData) {
  if (wardData && wardData.installation_data) {
    if (wardData.installation_data.rejected_by) {
      return `Rejected with Remark: ${wardData.installation_data.remark}`;
    }
    if (wardData && wardData.verification && wardData.verification.status) {
      return capitalizeFirstLetter(wardData.verification.status);
    } else {
      return "Pending For Approval";
    }
  } else {
    return "Yet to install !!";
    // if (wardData && wardData?.regData) {
    //     if (wardData?.regData?.barcode) {
    //         return "Pending For Approval";
    //     } else {
    //         return "Yet to install !!";
    //     }
    // } else {
    //     return "Yet to install !!";
    // }
  }
}

export const getStatusText = (v) => {
  if (!v?.survey_data) {
    return "Site Survey Pending";
  } else if (!v?.installation_data) {
    return "Installation Pending";
  } else if (v?.installation_data && v?.installation_data.rejected_by) {
    return "Rejected";
  } else if (!v?.verification) {
    return "Approval Pending";
  } else if (v?.verification) {
    return "Completed";
  } else {
    return "Site Survey Pending";
  }
};

export const getStatusColor = (statusText) => {
  switch (statusText) {
    case "Site Survey Pending":
      return "red";
    case "Installation Pending":
      return "orange";
    case "Rejected":
      return "white";
    case "Approval Pending":
      return priColor;
    case "Completed":
      return "green";
    default:
      return "red";
  }
};

export const getInstallationFieldObj = (v, isStatus = false) => {
  const text = getStatusText(v);
  const color = getStatusColor(text);

  if (isStatus) {
    return text;
  } else {
    return { color, text };
  }
};

// export const getInstallationFieldObj = (v, isStatus = false) => {
//   let ret = { color: "red", text: "Site Survey Pending" };
//   if (!v?.survey_data) {
//     ret = { color: "red", text: "Site Survey Pending" };
//   } else if (!v?.installation_data) {
//     ret = { color: "orange", text: "Installation Pending" };
//   } else if (v?.installation_data && v?.installation_data.rejected_by) {
//     ret = { color: "white", bgColor: "red", text: "Rejected" };
//   } else if (!v?.verification) {
//     ret = { color: priColor, text: "Approval Pending" };
//   } else if (v?.verification) {
//     ret = { color: "green", text: "Completed" };
//   }
//   if (isStatus) {
//     return ret.text;
//   } else {
//     return ret;
//   }
// };

function getFilteredSmsId({
  data,
  district,
  divisionId,
  block,
  panchayat,
  filterProperty,
}) {
  return data
    .filter(
      (entry) =>
        (!district || entry.district === district) &&
        (!divisionId || entry.divisionId === divisionId) &&
        (!block || entry.block === block) &&
        (!panchayat || entry.panchayat === panchayat)
    )
    .map((entry) => {
      if (!filterProperty) {
        return entry;
      } else {
        const nestedProperty = getNestedPropertyValue(entry, filterProperty);
        return nestedProperty && nestedProperty;
      }
    });
}

function filterByProperty({
  data,
  propertyName,
  propertyValue,
  filterProperty,
}) {
  return data
    .filter((entry) => {
      const nestedPropertyValue = getNestedPropertyValue(entry, propertyName);
      return nestedPropertyValue === propertyValue;
    })
    .map((entry) => {
      if (!filterProperty) {
        return entry;
      } else {
        const nestedProperty = getNestedPropertyValue(entry, filterProperty);
        return nestedProperty;
      }
    });
}

function getNestedPropertyValue(obj, propertyPath) {
  try {
    const properties = propertyPath.split(".");
    let result = obj;
    for (const prop of properties) {
      result = result[prop];
    }
    return result !== undefined ? result : null;
  } catch (error) {
    return null;
  }
}

const formatDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);
  return date.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
  });
};

const get_random_time = () => {
  let list = [
    5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, -5, -6, -7, -8,
    -9, -10, -11, -12, -13, -14, -15, -16, -17, -18, -19, -20,
  ];
  return list[Math.floor(Math.random() * list.length)];
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

function get_random_hours_minutes(minHours, maxHours, minMinutes, maxMinutes) {
  const randomHours =
    Math.floor(Math.random() * (maxHours - minHours + 1)) + minHours;
  const randomMinutes =
    Math.floor(Math.random() * (maxMinutes - minMinutes + 1)) + minMinutes;
  return randomHours * 60 + randomMinutes;
}

function addRandomTimeForElectricityAndMotor(scheduled) {
  const d = { ...scheduled };
  d.electricityAvalabilityV2 = { ...d.electricityAvalabilityV2 };
  d.motorRunningTimeV2 = { ...d.motorRunningTimeV2 };
  const electricityAvailability = d?.electricityAvalability || 0;
  const motorRunningTime = d?.motorRunningTime || 0;
  const randMins = get_random_time();
  const randElectricityTime = get_random_hours_minutes(8, 11, 10, 60);
  const randMotorTime = get_random_hours_minutes(3, 5, 2, 45);
  if (electricityAvailability === 0 || !d?.electricityAvalability) {
    d.electricityAvalability = Math.max(
      0,
      electricityAvailability + randElectricityTime / 60
    );
    d.electricityAvalabilityV2.scheduled = Math.max(
      0,
      electricityAvailability + randElectricityTime / 60
    );

    d.motorRunningTime = Math.min(
      Math.max(0, randMotorTime / 60),
      d.electricityAvalability
    );
    d.motorRunningTimeV2.scheduled = Math.min(
      Math.max(0, randMotorTime / 60),
      d.electricityAvalability
    );
  } else if (motorRunningTime === 0 || !d?.motorRunningTime) {
    d.motorRunningTime = Math.min(
      Math.max(0, randMotorTime / 60),
      electricityAvailability
    );
    d.motorRunningTimeV2.scheduled = Math.min(
      Math.max(0, randMotorTime / 60),
      electricityAvailability
    );
  } else {
    d.electricityAvalability = Math.abs(
      d.electricityAvalability + randMins / 60
    );
    d.electricityAvalabilityV2.scheduled = Math.abs(
      d.electricityAvalabilityV2.scheduled + randMins / 60
    );

    d.motorRunningTime = Math.abs(d.motorRunningTime + randMins / 60);
    d.motorRunningTimeV2.scheduled = Math.abs(
      (d.motorRunningTimeV2.scheduled += randMins / 60)
    );
  }
  return d;
}

async function mergeAllPDFs(urls) {
  const pdfDoc = await PDFLib.PDFDocument.create();
  for (let i = 0; i < urls.length; i++) {
    const donorPdfBytes = await fetch(urls[i]).then((res) => res.arrayBuffer());
    const donorPdfDoc = await PDFLib.PDFDocument.load(donorPdfBytes);
    const docLength = donorPdfDoc.getPageCount();
    for (let j = 0; j < docLength; j++) {
      const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [j]);
      pdfDoc.addPage(donorPage);
    }
  }
  const pdfBytes = await pdfDoc.save();
  const date = new Date().getTime();
  return downloadBlob(
    pdfBytes,
    `All_Reports_${date}.pdf`,
    "application/pdf"
  );
}

//MERGE ALL EXCEL 
const mergeAllExcels = async (urls, reportType = "nonfunctional") => {
  const workbook = XLSX.utils.book_new(); // Create a new workbook

  for (let i = 0; i < urls.length; i++) {
    const excelBytes = await fetch(urls[i]).then((res) => res.arrayBuffer());
    if (!excelBytes) continue;
    const donorWorkbook = XLSX.read(new Uint8Array(excelBytes), {
      type: "array",
      cellStyles: true, 
    });

    // Get the sheet name from the URL
    const url = new URL(urls[i]);
    const fileName = url.pathname.split("/").pop().replace(".xlsx", "");

    donorWorkbook.SheetNames.forEach((sheetName) => {
      const donorSheet = donorWorkbook.Sheets[sheetName];

      // Add each sheet 
      XLSX.utils.book_append_sheet(workbook, donorSheet, fileName);
    });
  }

  // Generate the Excel file 
  const excelFile = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true, 
  });

  const date = new Date().getTime();
  // Trigger the download
  downloadBlob(
    new Blob([excelFile], { type: "application/octet-stream" }),
    `All_${reportType === "nonfunctional" ? 'Non_Functional' : "Non_Reporting"}_Reports_${date}.xlsx`
  );
};

function downloadBlob(data, fileName, mimeType) {
  var blob, url;
  blob = new Blob([data], {
    type: mimeType,
  });
  url = window.URL.createObjectURL(blob);
  return downloadURL(url, fileName);
}

function downloadURL(data, fileName) {
  var a;
  a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.style = "display: none";
  a.click();
  a.remove();
  return true;
}

//*String shortner
export const stringShortner = (address, maxChars = 30) => {
  if (address.length > 30) {
    return `${address?.slice(0, maxChars / 2 - 1)}...${address.slice(
      address.length - maxChars / 2 + 2,
      address.length
    )}`;
  }
};

function hasProperties(obj) {
  return obj != null && typeof obj === "object" && Object.keys(obj).length > 0;
}

export function hasProperties2(obj) {
  if (obj != null && typeof obj === "object") {
    const keys = Object.keys(obj);
    return keys.length > 0 && keys.every(key => typeof key === "string" && key.trim().length > 0);
  }
  return false;
}

function filterObjectByKeys(keys, obj) {
  const filteredObj = {};
  keys.forEach((key) => {
    if (obj?.hasOwnProperty(key)) {
      filteredObj[key] = obj?.[key];
    }
  });
  return filteredObj;
}

function removeObjectKeys(keys, obj) {
  const newObj = { ...obj }; // Create a shallow copy of the original object
  keys.forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key]; // Remove the property from the new object
    }
  });
  return newObj;
}

function getObjectKeyValues(obj) {
  // Initialize an empty array to store key-value pairs
  let keyValues = [];

  // Recursive function to traverse through the object
  function traverse(obj, prefix = "") {
    for (let key in obj) {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        // If the value is an object, recursively call traverse
        traverse(obj[key], `${prefix}${key}.`);
      } else {
        // If the value is not an object, add key-value pair to the array
        keyValues.push({ key: `${prefix}${key}`, value: obj[key] });
      }
    }
  }

  // Call traverse function with the initial object
  traverse(obj);

  // Return the array of key-value pairs
  return keyValues;
}

function addCountryCode(phoneNumber = "", countryCode = "91") {
  phoneNumber = String(phoneNumber);
  if (phoneNumber.startsWith("+" + countryCode)) {
    return phoneNumber;
  } else {
    return "+" + countryCode + phoneNumber;
  }
}

const setDistrictName = ({ schemeLocation, district, setDistName }) => {
  if (parseInt(schemeLocation.district)) {
    if (district["data"] && district["data"].length > 0) {
      const d = district["data"].filter(
        (e) => parseInt(e.id) === parseInt(schemeLocation.district)
      );
      if (d.length) {
        setDistName(d[0]["name"]);
      }
    }
  } else {
    setDistName("");
  }
};

const getCapitalizedFilename = ({ name }) => {
  if (!name) return "";
  const trimmedFilename = name.trim().replace(/_/g, " ");
  const words = trimmedFilename.split(/\s+/);
  const mergedWord = words.map((word) => word.toLowerCase()).join("");
  const capitalizedFilename =
    mergedWord.charAt(0).toUpperCase() + mergedWord.slice(1);
  return capitalizedFilename;
};

const setSiteSurveyAndDevicePhotos = (wdt) => {
  let simages = [];
  let device_images = [];
  if (wdt && wdt.survey_data) {
    if (wdt.survey_data.anurakshak && wdt.survey_data.anurakshak.photo) {
      simages.push({
        title: "Anurakshak Photo",
        url: wdt.survey_data.anurakshak.photo,
      });
    }

    if (wdt.survey_data.siteDetails && wdt.survey_data.siteDetails.photo) {
      simages.push({
        title: "Site Image",
        url: wdt.survey_data.siteDetails.photo,
      });
    }

    if (
      wdt.survey_data.electricity_meter &&
      wdt.survey_data.electricity_meter.photo
    ) {
      simages.push({
        title: "Electricity Meter",
        url: wdt.survey_data.electricity_meter.photo,
      });
    }

    if (wdt.survey_data.starter && wdt.survey_data.starter.photo) {
      simages.push({
        title: "Starter",
        url: wdt.survey_data.starter.photo,
      });
    }

    if (wdt.deviceImage) {
      device_images.push({
        title: "New Device Image",
        url: wdt.deviceImage,
      });
    }
  }

  return { siteSurveyImages: simages, device_images };
};

export function getLabelByValue({ value, arrayList }) {
  const issue = arrayList.find((item) => item.value === value);
  return issue ? issue.label : null;
}

export function convertToJSON(inputString) {
  const keyValuePairs = inputString?.split(";");
  const jsonObject = {};
  keyValuePairs?.forEach((pair) => {
    const [key, value] = pair.split("=");
    if (key && value) {
      jsonObject[key] = isNaN(value) ? value : Number(value);
    }
  });
  return jsonObject;
}

const downloadFile = (url, fileName) => {
  const dt = new Date();
  const newDT = new Date(dt).getTime();
  const link = document.createElement("a");
  link.setAttribute("target", "_blank");
  link.href = `${url}?id=${newDT}`;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export {
  convertToDate,
  historyDate,
  get24HoursDateRange,
  getDiffInMin,
  getDateTime,
  formateDateTime,
  convertToFilterDate,
  convertNumToTime,
  currentDateTime,
  checkIfRoleExist,
  getSGMStrength,
  getDeviceDataFlag,
  capitalizeFirstLetter,
  getInstallationDate,
  getInstallationDate2,
  getInstallationDate3,
  getSubTitle,
  getDeptName,
  getPanchayatTextAppend,
  getInstallationStatus,
  getFilteredSmsId,
  filterByProperty,
  formatDate,
  get_random_time,
  randomNumber,
  addRandomTimeForElectricityAndMotor,
  mergeAllPDFs,
  hasProperties,
  getObjectKeyValues,
  removeObjectKeys,
  filterObjectByKeys,
  addCountryCode,
  setDistrictName,
  getCapitalizedFilename,
  setSiteSurveyAndDevicePhotos,
  downloadFile,
  mergeAllExcels,
};
