import {
  Box,
  Grid,
  Typography,
  Container,
  Breadcrumbs,
  Link,
  Button,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRight";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useSettings from "../../hooks/useSettings";
import { LocationFilter } from "../../components/dashboard/shemes";
import { getSchemes, resetSchemes, setFilters } from "../../slices/siteInfo";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/Loader/Loader";
import { resetOpData } from "../../slices/operationalData";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { downloadFile, mergeAllExcels, mergeAllPDFs } from "../../utils/helper";
import { resetdownloadData } from "../../slices/downloadReport";
import {
  REPORT_CONTAINER,
  STORAGE_ACCOUNT,
  NON_FUNCTIONAL_REPORTS,
  NON_REPORTING_REPORTS,
} from "../../constants";
import toast from "react-hot-toast";

export const downloadUploadedReportFile = async (blobName) => {
  const url = `https://${STORAGE_ACCOUNT}.blob.core.windows.net/${REPORT_CONTAINER}/${blobName}`;
  try {
    const response = await fetch(url);
    if (response?.ok) {
      return response;
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const Reports = (props) => {
  const { settings } = useSettings();
  const [schemeLocation, setSchemeLocation] = useState({});
  const dispatch = useDispatch();
  const { district, schemes, lastSearchFilter } = useSelector(
    (state) => state.siteinfo
  );
  const { reportDownloadData: reportReqObj } = useSelector(
    (state) => state.downloadReport
  );
  const [distName, setDistName] = useState("");
  const [allSchemes, setAllSchemes] = useState([]);
  const [enableDownload, setEnableDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoaderMsg] = useState(null)

  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      setEnableDownload(false);
      setAllSchemes([]);
      dispatch(resetSchemes());
      dispatch(resetOpData());
    }
    setSchemeLocation(data);
  };

  useEffect(() => {
    if (lastSearchFilter?.district) {
      const d = district?.["data"]?.filter(
        (e) => e.id === lastSearchFilter?.district
      );
      if (d?.length) {
        setDistName(d[0]["name"]?.toLowerCase());
      }
    }
    if (lastSearchFilter?.district && distName) {
      setEnableDownload(true);
    }
  }, [distName, district, lastSearchFilter]);

  useEffect(() => {
    if (reportReqObj && reportReqObj["status"] == "SUCCESS") {
      const anchor = document.createElement("a");
      anchor.href = reportReqObj["data"];
      anchor.target = "_blank";
      anchor.download = reportReqObj["data"];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      dispatch(resetdownloadData());
    }
  }, [reportReqObj]);

  useEffect(() => {
    if (parseInt(schemeLocation.district)) {
      if (district["data"] && district["data"].length > 0) {
        const d = district["data"].filter(
          (e) => e.id == schemeLocation.district
        );
        if (d.length) {
          setDistName(d[0]["name"]?.toLowerCase());
        }
      }
    } else {
      setDistName("");
    }
  }, [schemeLocation.district]);

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      let d = {};
      if (schemeLocation.district) {
        d["district"] = schemeLocation.district;
      }
      if (schemeLocation.block) {
        d["block"] = schemeLocation.block;
      }
      if (schemeLocation.panchayat) {
        d["panchayat"] = schemeLocation.panchayat;
      }
      if (schemeLocation.ward) {
        d["ward"] = schemeLocation.ward;
      }
      dispatch(setFilters(schemeLocation));
      dispatch(getSchemes(d));
    }
  }, [schemeLocation]);

  useEffect(() => {
    setAllSchemes(schemes["data"]);
  }, [schemes]);



  const downloadReportFile = async (type) => {
    if (
      lastSearchFilter &&
      lastSearchFilter["district"] &&
      type === "nonfunctional"
    ) {
      const reportLink = NON_FUNCTIONAL_REPORTS.find(
        (e) => e.id === lastSearchFilter["district"]
      );
      if (reportLink && reportLink.report) {
        downloadFile(reportLink.report);
      } else {
        toast("Report not found!");
        // dispatch(downloadReportReducer(lastSearchFilter["district"], historyDate(1), type, 0))
      }
    } else if (
      lastSearchFilter &&
      lastSearchFilter["district"] &&
      type === "nonreporting"
    ) {
      const reportLink = NON_REPORTING_REPORTS.find(
        (e) => e.id === lastSearchFilter["district"]
      );
      if (reportLink && reportLink.report) {
        downloadFile(reportLink.report);
      } else {
        toast("Report not found!");
        // dispatch(downloadReportReducer(lastSearchFilter["district"], historyDate(1), type, 0))
      }
    } else {
      alert("Something is wrong!!");
    }
  };

  const handleDownloadAllNFW = async ({ type = "nonfunctional" }) => {
    if (district?.data && district?.data?.length > 0) {
      const allDistrictIds = district?.data.map((dis) => dis.id);
      let REPORTS_ARRAY = NON_FUNCTIONAL_REPORTS;
      if (type === "nonreporting") {
        REPORTS_ARRAY = NON_REPORTING_REPORTS;
      }
      const tempReports = REPORTS_ARRAY.filter((file) =>
        allDistrictIds.includes(file.id)
      );
      const allReportsLink = tempReports.map((item) => item.report + `?id=${new Date().getTime()}`);

      try {
        setLoading(true);
        setLoaderMsg("Downloading all reports...")
        // const res = await mergeAllPDFs(allReportsLink);
        const res = await mergeAllExcels(allReportsLink, type);
        if (res) {
          setLoading(false);
          toast.success("All reports downloaded");
        }
      } catch (error) {
        toast.error("Reports not found!");
      } finally {
        setLoading(false);
        setLoaderMsg(null)
      }
    } else {
      toast.error("Districts not found!");
    }
  };

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Download Reports
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Reports
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ py: 3 }}>
            <LocationFilter
              onChange={onChangeLocation}
              initialValues={lastSearchFilter}
              schemes={allSchemes}
              downloadExcel={false}
              showDistrictOnly={true}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} xs={12}>
              <Button
                onClick={() => {
                  downloadReportFile("nonfunctional");
                }}
                disabled={!enableDownload}
                color="primary"
                size="large"
                sx={{ mr: 3 }}
                variant="contained"
              >
                <CloudDownloadIcon style={{ marginRight: 10 }} />
                Download Non Functional Report
              </Button>

              <Button
                onClick={() => handleDownloadAllNFW({ type: "nonfunctional" })}
                color="primary"
                size="large"
                sx={{ mr: 3 }}
                variant="contained"
              // disabled={!enableDownload}
              >
                <CloudDownloadIcon style={{ marginRight: 10 }} />
                Download All Non-Functional Reports
              </Button>
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <Button
                onClick={() => {
                  downloadReportFile("nonreporting");
                }}
                disabled={!enableDownload}
                color="primary"
                size="large"
                sx={{ mr: 3 }}
                variant="contained"
              >
                <CloudDownloadIcon style={{ marginRight: 10 }} />
                Download Non Reporting Report
              </Button>
              <Button
                onClick={() => handleDownloadAllNFW({ type: "nonreporting" })}
                color="primary"
                size="large"
                sx={{ mr: 3 }}
                variant="contained"
              // disabled={!enableDownload}
              >
                <CloudDownloadIcon style={{ marginRight: 10 }} />
                Download All Non-Reporting Reports
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {((reportReqObj && reportReqObj["status"] === "loading") || loading) && (
        <Loader text={loadingMsg} />
      )}
    </>
  );
};

export default Reports;
